import { Avatar, PresenceBadgeStatus, Tooltip } from '@fluentui/react-components';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { EasyUserProps } from '../common/interfaces';
import { Presence, User } from '@microsoft/microsoft-graph-types';
import { EuiLoadingSpinner, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { Client } from '@microsoft/microsoft-graph-client'; // Import Client type

interface Props extends EasyUserProps {
  graph: Client | null;
  isLoading: boolean;
  onPresenceChange?: (availability: string | undefined) => void;
}

export const EasyUser: React.FC<Props> = React.memo(
  ({
    userId,
    displayName,
    resultType,
    showDetails = false,
    graph,
    isLoading,
    onPresenceChange,
  }) => {
    const [photoUrl, setPhotoUrl] = useState<string>();
    const [profileData, setProfileData] = useState<User>();
    const [presenceData, setPresenceData] = useState<Presence>();
    const pollingInterval = useRef<NodeJS.Timeout>();

    const fetchPresence = useCallback(async () => {
      try {
        const presence = await graph?.api(`/users/${userId}/presence`).get();
        setPresenceData(presence);
        onPresenceChange?.(presence?.availability);
      } catch (e) {
        console.error('Error fetching presence:', e);
        onPresenceChange?.(undefined);
      }
    }, [userId, graph, onPresenceChange]);

    // Add useEffect for presence polling
    useEffect(() => {
      if (!graph || resultType !== 'entraid') return;

      // Initial fetch
      fetchPresence();

      // Set up polling interval
      pollingInterval.current = setInterval(() => {
        fetchPresence();
      }, 15000); // 15 seconds

      // Cleanup function
      return () => {
        if (pollingInterval.current) {
          clearInterval(pollingInterval.current);
        }
      };
    }, [fetchPresence, graph, resultType]);

    const fetchProfileData = useCallback(async () => {
      try {
        const profile = await graph?.api(`/users/${userId}`).get();
        setProfileData(profile);
        try {
          const photoBlob = await graph?.api(`/users/${userId}/photo/$value`).get();
          const url = URL.createObjectURL(photoBlob);
          setPhotoUrl(url);
        } catch {
          setPhotoUrl('');
        }
      } catch (e) {
        console.error('Error in batch or photo request', e);
      }
    }, [userId, graph]);

    useEffect(() => {
      const initialize = async () => {
        if (!graph) return;
        if (resultType !== 'entraid') return;

        await fetchProfileData();
        await fetchPresence();
      };

      initialize();
    }, [userId, resultType, fetchPresence, fetchProfileData, graph]);

    const parsePresenceStatus = (
      availability?: string,
    ): PresenceBadgeStatus => {
      switch (availability?.toLowerCase()) {
        case 'available':
          return 'available';
        case 'busy':
        case 'donotdisturb':
          return 'busy';
        case 'away':
        case 'bemrighback':
          return 'away';
        case 'offline':
          return 'offline';
        default:
          return 'unknown';
      }
    };

    const getPresenceActivity = (presence?: Presence): string => {
      if (!presence) return '';

      if (presence.activity?.toLowerCase() === 'outofoffice' ||
        presence.activity?.toLowerCase() === 'offwork') {
        return 'Out of office';
      }

      return presence.availability || '';
    };

    return (
      <>
        {isLoading ? (
          <EuiLoadingSpinner size="l" />
        ) : (
          showDetails ? (
            <EuiFlexGroup direction='row' responsive={false} wrap={false} gutterSize="s" alignItems="center">
              <EuiFlexItem grow={false}>
                <Avatar
                  size={48}
                  color="navy"
                  name={profileData?.displayName ? profileData.displayName : displayName}
                  shape="circular"
                  badge={presenceData ? {
                    outOfOffice:
                      presenceData.activity?.toLowerCase() === 'outofoffice' ||
                      presenceData.activity?.toLowerCase() === 'offwork',
                    status: parsePresenceStatus(presenceData.availability ?? '') as PresenceBadgeStatus,
                  } : undefined}
                  image={photoUrl ? { src: photoUrl } : undefined}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction="column" gutterSize="none">
                  <EuiFlexItem>
                    <EuiText size="s" style={{ fontSize: '14px' }}>
                      {displayName}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText size="xs" color="subdued">
                      {getPresenceActivity(presenceData)}
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : (
            presenceData && getPresenceActivity(presenceData) ? (
              <Tooltip content={getPresenceActivity(presenceData)} relationship="description" withArrow>
                <Avatar
                  size={48}
                  color="navy"
                  name={profileData?.displayName ? profileData.displayName : displayName}
                  shape="circular"
                  badge={presenceData ? {
                    outOfOffice:
                      presenceData.activity?.toLowerCase() === 'outofoffice' ||
                      presenceData.activity?.toLowerCase() === 'offwork',
                    status: parsePresenceStatus(presenceData.availability ?? '') as PresenceBadgeStatus,
                  } : undefined}
                  image={photoUrl ? { src: photoUrl } : undefined}
                />
              </Tooltip>
            ) : (
              <Avatar
                size={48}
                color="navy"
                name={profileData?.displayName ? profileData.displayName : displayName}
                shape="circular"
                badge={presenceData ? {
                  outOfOffice:
                    presenceData.activity?.toLowerCase() === 'outofoffice' ||
                    presenceData.activity?.toLowerCase() === 'offwork',
                  status: parsePresenceStatus(presenceData.availability ?? '') as PresenceBadgeStatus,
                } : undefined}
                image={photoUrl ? { src: photoUrl } : undefined}
              />
            )
          )
        )}
      </>
    );
  }
);
