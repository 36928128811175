// src/hooks/useConfiguration.tsx
import { useCallback } from 'react';
import axios from 'axios';
import { useToken } from './useToken';
import config from '../common/config';
import {
    PersonalView,
    UserConfiguration,
} from '../common/interfaces';

export const useConfiguration = () => {
    const { getToken } = useToken();

    const getUserConfiguration = useCallback(async (userId: string): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.get(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const updatePersonalView = useCallback(async (
        userId: string,
        updatedView: PersonalView
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}/personalView/${updatedView.id}`,
                updatedView,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const postPersonalView = useCallback(async (
        userId: string,
        view: PersonalView
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.post(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}/personalViews`,
                view,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const deletePersonalView = useCallback(async (
        userId: string,
        viewId: string
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.delete(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}/personalViews/${viewId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const updateDefaultView = useCallback(async (
        userId: string,
        viewId: string
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.patch(
                `${config.apiEndpoint}/easydirectory/configurations/${userId}/default/${viewId}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [getToken]);

    const postFavorite = useCallback(async (
        userId: string,
        favoriteId: string,
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.post(
                config.apiEndpoint +
                '/easydirectory/configurations/' +
                userId +
                '/favorites',
                favoriteId,
                {
                    headers: {
                        Authorization: token?.token,
                        'Content-Type': 'application/json',
                    },
                },
            );

            return response.data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }, [getToken]);

    const deleteFavorite = useCallback(async (
        userId: string,
        favoriteId: number,
    ): Promise<UserConfiguration | null> => {
        try {
            const token = await getToken();
            const response = await axios.delete(
                config.apiEndpoint +
                '/easydirectory/configurations/' +
                userId +
                '/favorites/' +
                favoriteId,
                {
                    headers: {
                        Authorization: token?.token,
                        'Content-Type': 'application/json',
                    },
                },
            );

            return response.data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }, [getToken]);

    const postSyncContacts = useCallback(async (contactIds: string[]): Promise<{data: string[], error?: string}> => {
        if (!contactIds || contactIds.length === 0) {
            return {
                data: [],
                error: 'No contact IDs provided'
            };
        }

        try {
            const token = await getToken(["User.Read", "Contacts.ReadWrite"]);
            
            if (!token?.token) {
                return {
                    data: [],
                    error: 'Authentication failed - no valid token'
                };
            }

            const response = await axios.post(
                `${config.apiEndpoint}/me/contacts/sync/`,
                contactIds,
                {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!response.data) {
                return {
                    data: [],
                    error: 'No data received from server'
                };
            }

            return {
                data: response.data
            };

        } catch (error) {
            // Handle specific axios errors
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Server responded with error status
                    return {
                        data: [],
                        error: `Server error: ${error.response.status} - ${error.response.data?.message || error.message}`
                    };
                } else if (error.request) {
                    // Request made but no response received
                    return {
                        data: [],
                        error: 'No response received from server'
                    };
                }
            }

            // Generic error handling
            console.error('Error syncing contacts:', error);
            return {
                data: [],
                error: 'Failed to sync contacts'
            };
        }
    }, [getToken]);

    const deleteSyncContacts = useCallback(async (contactId: string): Promise<{data: string[], error?: string}> => {
        if (!contactId) {
            return {
                data: [],
                error: 'No contact ID provided'
            };
        }

        try {
            const token = await getToken(["User.Read", "Contacts.ReadWrite"]);
            
            if (!token?.token) {
                return {
                    data: [],
                    error: 'Authentication failed - no valid token'
                };
            }

            const response = await axios.delete(
                `${config.apiEndpoint}/me/contacts/sync/${contactId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!response.data) {
                return {
                    data: [],
                    error: 'No data received from server'
                };
            }

            return {
                data: response.data
            };

        } catch (error) {
            // Handle specific axios errors
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Server responded with error status
                    return {
                        data: [],
                        error: `Server error: ${error.response.status} - ${error.response.data?.message || error.message}`
                    };
                } else if (error.request) {
                    // Request made but no response received
                    return {
                        data: [],
                        error: 'No response received from server'
                    };
                }
            }

            // Generic error handling
            console.error('Error deleting synced contact:', error);
            return {
                data: [],
                error: 'Failed to delete synced contact'
            };
        }
    }, [getToken]);

    return {
        getUserConfiguration,
        updatePersonalView,
        postPersonalView,
        deletePersonalView,
        updateDefaultView,
        postFavorite,
        deleteFavorite,
        postSyncContacts,
        deleteSyncContacts,
    };
};